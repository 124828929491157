import React from "react";

const Legend = ({ selectedModelName }) => {
  const getLegendContent = () => {
    switch (selectedModelName) {
      case "oil_spills":
        return (
          <div>
            <div>
            <span style={{ backgroundColor: '#00FFFF', display: 'inline-block', width: '15px', height: '15px' }}></span> Oil Slick
            </div>
            <div>
            <span style={{ backgroundColor: '#FA28FF', display: 'inline-block', width: '15px', height: '15px' }}></span> Affected Fishing Ground
            </div>
            <div>
            <span style={{ backgroundColor: 'orange', display: 'inline-block', width: '15px', height: '15px' }}></span> Affected Port Approach
            </div>
            <div>
            <span style={{ backgroundColor: 'orange', display: 'inline-block', width: '25px', height: '5px' }}></span> Port Traffic
            </div>
          </div>
        );
      case "ship_detection":
        return (
          <div>
            <div>
            <span style={{ backgroundColor: 'green', display: 'inline-block', width: '15px', height: '15px' }}></span> Detected Vessel
            </div>
            <div>
            <span style={{ backgroundColor: 'red', borderRadius: '50%', display: 'inline-block', width: '15px', height: '15px' }}></span> AIS Ping
            </div>
          </div>
        );
      case "fire_detection":
        return (
          <div>
            <div>
          
            <span style={{ backgroundColor: 'brown', display: 'inline-block', width: '15px', height: '15px' }}></span>    Active Fire
            </div>
            <div>
            <span style={{ backgroundColor: 'orange', display: 'inline-block', width: '15px', height: '15px' }}></span> Burnt Area
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div>
            <span style={{ background: 'linear-gradient(to right, black, white)', display: 'inline-block', width: '15px', height: '15px' }}></span>Building Presence
            </div>
            <div>
            <span style={{ backgroundColor: 'orange', display: 'inline-block', width: '15px', height: '15px' }}></span>
           Lost Building Area
            </div>
          </div>
        );
    }
  };

  return (
    <div className="legend">
      {getLegendContent()}
    </div>
  );
};

export default Legend;
