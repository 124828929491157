export const loadJSON = async (url) => {
  const response = await fetch(url);

  if (!response.ok) throw new Error("network response was not ok");
  const data = await response.json();

  data.models.forEach((model) => {
    model.timestamp_dict.reverse();
    model.timestamp.reverse();
  });
  const ans = [];
  ans.push(data);
  return ans;
};

export const getCityLatLongForCity = (jsonData, cityName) => {
  const cityEntry = jsonData.find((city) => city.city === cityName);
  return cityEntry ? { lat: cityEntry.lat, long: cityEntry.long } : {};
};

export const getCityData = (jsonData, cityName) => {
  const cityData = jsonData.find((city) => city.city == cityName);
  if (!cityData) return null;
  return cityData;
};

export const getModelData = (jsonData, cityName, model_name) => {
  const cityEntry = jsonData.find((city) => city.city == cityName);
  if (!cityEntry) return null;

  const details = cityEntry.models;
  const specificModelDetails = details.find((e) => e[model_name]);
  const timestamp_dict = specificModelDetails.timestamps;
  const timestamps = Object.keys(timestamp_dict);
  return { timestamp_dict, timestamps };
};

export const getAnalyticsData = (timestamp, cityName, jsonData) => {
  console.log(timestamp, jsonData, cityName);
  const cityData = jsonData.find((city) => city.city == cityName);
  if (cityData) {
    let x = cityData.models.timestamp_dict;
    let y = x.find((e) => e[timestamp]);
    let ans = [y[timestamp], cityData.models.boxTitle, cityData.models.data];
    return ans;
  }
  return null;
};

export const getClusteringCoord = (jsonData, timestamp) => {
  if (timestamp == "alert" || timestamp == "2024-08-15") {
    const z = jsonData.geojson_data.features;
    const coordArr = [];
    z.forEach((e) => coordArr.push(e.geometry.coordinates));
    return coordArr;
  } else {
    if (jsonData[0].models[0].title == "fire_detection") {
      const x = jsonData[0].models[0].timestamp_dict;
      const y = x.find((item) => item.hasOwnProperty(timestamp));
      const z = y.geojson_data.features;
      const coordArr = [];
      z.forEach((e) => coordArr.push(e.geometry.coordinates));
      return coordArr;
    } else {
      return [];
    }
  }
};
