import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import CitySelector from "./components/CitySelector/CitySelector";
import { loadJSON, getCityData } from "./utils/apicalls";

const App = () => {
  const [cityDataList, setCityDataList] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedCardDetails, setSelectCardDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDirection, setShowDirection] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [upcomingPassDetails, setUpcomingPassDetails] = useState([]);
  const [upcomingPassTime, setUpcomingPassTime] = useState(null);
  const [deliverImageTime, setDeliverImageTime] = useState(null);
  const [deliverInsightsTime, setDeliverInsightsTime] = useState(null);
  const [isStatusBtnVisible, setIsStatusBtnVisible] = useState(false);
  const [modelTitle, setModelTitle] = useState("Building Detection");
  const [filteredInfoCards, setFilteredInfoCards] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [indexOf, setIndexOf] = useState(-1);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchCityList = async () => {
      try {
        const response = await fetch(
          "https://apidcit.skyserve.ai/v1/location/",
          { method: "GET" }
        );
        let cities = [];
        const ans = await response.json();
        if (ans) {
          ans.output.forEach((e) => cities.push(e.location_name));
        }
        setCitiesArray(cities);
        setSelectedCityName(ans.output[0].location_name);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchCityList();
  }, []);

  useEffect(() => {
    if (citiesArray.length > 0) {
      const models_names = [];
      const fetchCityDataInfo = async (city) => {
        try {
          console.log(city, "city");
          const jsonData1 = await loadJSON(
            `https://apidcit.skyserve.ai/v1/default/?city_name=${city}`
          );
          console.log(jsonData1, "jsond");
          const cityData = await getCityData(jsonData1, city);
          console.log(cityData, "cityData");
          // setCityLat(cityData.lat);
          // setCityLong(cityData.lon);
          const trimmedInfoCards = cityData.models;
          const ans = trimmedInfoCards.filter((e) => {
            return e.timestamp_dict.length > 0;
          });
          models_names.push({
            key: ans[0].title,
            value: ans,
            city: cityData.city,
            lat: cityData.lat,
            lng: cityData.long,
            timer: {},
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      const fetchAllCitiesData = async () => {
        console.log(citiesArray, "arr");
        await Promise.all(citiesArray.map((city) => fetchCityDataInfo(city)));
        const dataSet = [];
        models_names.forEach((model) => {
          dataSet.push({
            models: model.value,
            city: model.city,
            lat: model.lat,
            lng: model.lng,
          });
        });
        console.log(dataSet, "dataset");
        setFilteredInfoCards(dataSet);
        setLoading(false);
      };
      fetchAllCitiesData();
    }
  }, [citiesArray]);

  useEffect(() => {
    if (selectedCardDetails) {
      // upcomingPassDetails.forEach((e) => {
      //   if (
      //     e.city == selectedCityName &&
      //     e.model_name == selectedCardDetails.title
      //   ) {
      //     setIsStatusBtnVisible(true);
      //   } else setIsStatusBtnVisible(false);
      // });
      const flag = upcomingPassDetails.some(
        (e) =>
          e.city == selectedCityName &&
          e.model_name == selectedCardDetails.title
      );
      setIsStatusBtnVisible(flag);
    }
  }, [selectedCityName, selectedCardDetails, upcomingPassDetails]);

  // useEffect(() => {
  //   if (showDirection) {
  //     setTimeout(() => {
  //       setShowDirection(false);
  //     }, 5000);
  //   }
  // }, [showDirection]);

  const timeConverter = (totalSeconds) => {
    const days = Math.floor(totalSeconds / (24 * 3600));
    totalSeconds %= 24 * 3600;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  };

  useEffect(() => {
    if (upcomingPassDetails.length > 0) {
      intervalRef.current = setInterval(() => {
        let allZero = true;

        setUpcomingPassDetails((prevData) => {
          return prevData.map((item) => {
            const newItem = { ...item };
            newItem.timer = { ...item.timer };

            if (newItem.timer.upcoming_pass > 0) {
              newItem.timer.upcoming_pass -= 1;
              allZero = false;
            }

            if (newItem.timer.image > 0) {
              newItem.timer.image -= 1;
              allZero = false;
            }

            if (newItem.timer.insights > 0) {
              newItem.timer.insights -= 1;
              allZero = false;
            }

            return newItem;
          });
        });

        if (allZero) {
          clearInterval(intervalRef.current);
        }
      }, 1000);

      return () => clearInterval(intervalRef.current);
    }
  }, [upcomingPassDetails]);

  const checkStatus = () => {
    if (selectedCardDetails) {
      setShowDirection(false);
      const x = upcomingPassDetails.filter((e) => {
        return e.city == selectedCityName;
      });
      const selectedModel = selectedCardDetails.title;
      const ans = x.find((e) => {
        return e.model_name == selectedModel ? e : null;
      });
      if (ans) {
        setShowStatus(!showStatus);
        const ind = upcomingPassDetails.indexOf(ans);
        if (indexOf == -1) setIndexOf(ind);
        else setIndexOf(-1);
      } else setShowStatus(false);
    }
  };

  useEffect(() => {
    if (!showStatus) setIndexOf(-1);
    if (indexOf == -1) setShowStatus(false);
  }, [showStatus, indexOf]);

  useEffect(() => {
    if (showStatus && indexOf >= 0) {
      const a = timeConverter(upcomingPassDetails[indexOf].timer.upcoming_pass);
      setUpcomingPassTime(a);
      const b = timeConverter(upcomingPassDetails[indexOf].timer.image);
      setDeliverImageTime(b);
      const c = timeConverter(upcomingPassDetails[indexOf].timer.insights);
      setDeliverInsightsTime(c);
    }
  }, [showStatus, upcomingPassDetails]);

  const handleChange = (event) => {
    const selectedCity = cityDataList.find(
      (city) => city.location_name === event.target.value
    );

    if (selectedCity) {
      setSelectedCityName(selectedCity.location_name);
      setSelectCardDetails(null);
      setShowStatus(false);
      setIndexOf(-1);
    }
  };

  useEffect(() => {
    setSelectCardDetails(null);
    setShowStatus(false);
    setIndexOf(-1);
  }, [selectedCityName]);

  const getSelectedCityCardDetials = (details, cityName) => {
    setSelectedCityName(cityName);
    setSelectCardDetails(details);
    const substrings = details.title.split("_");

    const capitalizedSubstrings = substrings.map((substring) => {
      return substring.charAt(0).toUpperCase() + substring.slice(1);
    });

    const finalStr = capitalizedSubstrings.join(" ");
    setModelTitle(finalStr);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <div className="content">
        <CitySelector
          cityName={selectedCityName}
          setShowDirection={setShowDirection}
          setUpcomingPassDetails={setUpcomingPassDetails}
          getSelectedCityCardDetials={getSelectedCityCardDetials}
          setShowStatus={setShowStatus}
          upcomingPassDetails={upcomingPassDetails}
          filteredInfoCards={filteredInfoCards}
          modelTitle={modelTitle}
          isStatusBtnVisible={isStatusBtnVisible}
          checkStatus={checkStatus}
          showDirection={showDirection}
          showStatus={showStatus}
          upcomingPassTime={upcomingPassTime}
          deliverImageTime={deliverImageTime}
          deliverInsightsTime={deliverInsightsTime}
        />
      </div>
    </div>
  );
};

export default App;
