import React, { useState, useEffect } from "react";
import "./DatasetDescription.css";

function DatasetDescription({ title, upcomingPassDetails, city, openNav }) {
  const [timeLeft, setTimeLeft] = useState(100);
  const [progress, setProgress] = useState(0);
  const [newObject, setNewObject] = useState(null);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [showToolTip, setShowToolTip] = useState(false);

  useEffect(() => {
    const x = upcomingPassDetails.find((e) => {
      return e.city == city && e.model_name == title ? e : null;
    });
    setNewObject(x);
  }, [city, upcomingPassDetails]);

  useEffect(() => {
    const substrings = title.split("_");

    const capitalizedSubstrings = substrings.map((substring) => {
      return substring.charAt(0).toUpperCase() + substring.slice(1);
    });

    const finalStr = capitalizedSubstrings.join(" ");
    setUpdatedTitle(finalStr);
  }, [title]);

  useEffect(() => {
    if (newObject) setTimeLeft(newObject.timer.insights);
  }, [upcomingPassDetails]);

  useEffect(() => {
    setProgress(((100 - timeLeft) / 70) * 100);
  }, [timeLeft, 100]);

  return (
    <div
      className={`dataset-description`}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      {updatedTitle == "Fire Detection" ? (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "2.3em" }}
        >
          local_fire_department
        </span>
      ) : updatedTitle == "Building Detection" ? (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "2.3em" }}
        >
          home_work
        </span>
      ) : updatedTitle == "Oil Spills" ? (
        <span class="material-symbols-outlined" style={{ fontSize: "2.3em" }}>
          oil_barrel
        </span>
      ) : (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "2.3em" }}
        >
          directions_boat
        </span>
      )}
      {showToolTip && <div className="left-tooltip-box"> {updatedTitle === "Ship Detection"? "Vessel Detection":updatedTitle === "Oil Spills"? "Oil Slicks":updatedTitle}</div>}

      {/* <div className="progress-bar-cover">
        {newObject && (
          <div
            className="progress-bar"
            style={{ width: `${progress}px` }}
          ></div>
        )}
      </div> */}

      {/* <p className="unit">Unit : {unit}</p>
      <p className="value">Value : {hoveredValue !== null ? hoveredValue : value}</p> */}
    </div>
  );
}

export default DatasetDescription;
