import React, { useState, useEffect } from "react";
import "./InfoCards.css";
import DatasetDescription from "./DatasetDescription";

function InfoCards({
  card,
  cardCity,
  city,
  citylat,
  citylong,
  onClick,
  isSelected,
  upcomingPassDetails,
  openNav,
  selectedModel,
}) {
  const [selectedCard, setSelectedCard] = useState("building_detection");

  useEffect(() => {
    if (
      selectedModel &&
      selectedModel.city == cardCity &&
      selectedModel.models[0].title == card.title
    ) {
      setSelectedCard(card.title);
    } else setSelectedCard("");
  }, [selectedModel]);

  const handleCardClick = (e) => {
    // Prevent event propagation to avoid triggering GraphContainer clicks
    e.stopPropagation();
    setSelectedCard("");
    onClick(card.title, city);
  };

  return (
    <div
      className={`info-card ${
        selectedCard == "building_detection"
          ? "selected-white"
          : selectedCard == "ship_detection"
          ? "selected-blue"
          : selectedCard == "fire_detection"
          ? "selected-red"
          : selectedCard == "oil_spills"
          ? "selected-orange"
          : ""
      }`}
      // style={{ backgroundImage: "url(/Images/image.png" }}
      // style={{
      //   backgroundImage: `url(${
      //     card.title === "fire_detection"
      //       ? "/Images/Wildfire.png"
      //       : card.title === "building_detection"
      //       ? "/Images/png.png"
      //       : card.title === "ship_detection"
      //       ? "/Images/Ship.png"
      //       : "/Images/default.png" // Default image in case none of the conditions match
      //   })`,
      // }}
      onClick={handleCardClick}
    >
      <DatasetDescription
        title={card.title}
        upcomingPassDetails={upcomingPassDetails}
        city={city}
        openNav={openNav}
      />
    </div>
  );
}

export default InfoCards;
