import React, { useState, useEffect } from "react";
import "./AnalyticsBox.css";
import analyticsData from "./analyticsData";
import { getAnalyticsData } from "../../utils/apicalls";

function AnalyticsBox({
  selectedTimeStamp,
  cityName,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
}) {
  const [analyticsStats, setAnalyticsStats] = useState(null);
  const [boxTitle, setBoxTitle] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchAnalyticsDetails = async (
      cityName,
      selectedTimeStamp,
      jsonData
    ) => {
      try {
        const jsonData1 = await getAnalyticsData(
          selectedTimeStamp,
          cityName,
          jsonData
        );
        setAnalyticsStats(jsonData1[0]);
        setBoxTitle(jsonData1[1]);
        setData(jsonData1[2]);
      } catch (error) {
        console.log(error);
        // setLoading(false);
      }
    };
    const jsonData = analyticsData();
    fetchAnalyticsDetails(cityName, selectedTimeStamp, jsonData);
  }, [cityName, selectedTimeStamp]);
  return (
    <>
      {analyticsStats && (
        <div
          className="analytics-box"
          onClick={() => setIsAnalyticsLayer(!isAnalyticsLayer)}
        >
          <div
            className={`analytics-box-heading ${
              isAnalyticsLayer ? "change-color" : ""
            }`}
          >
            <span className="material-symbols-outlined">analytics</span>
            <h5> Analytics</h5>
          </div>
          <div className="analytics-detail-box">
            <div className="current-date-details">
              <div className="analytics-details-header">
                <h2 style={{ fontSize: "1em" }}>
                  {analyticsStats.active_fires}
                </h2>
                <p>{boxTitle}</p>
                <p
                  className={`${
                    analyticsStats.downOrUp == "up"
                      ? "ab-status"
                      : "ab-status-green"
                  }`}
                >
                  {data}
                </p>
              </div>
              <div className="analytics-details-header">
                <h2 style={{ fontSize: "1em" }}>{analyticsStats.detected}</h2>
                <p>{analyticsStats.detected_title}</p>
                <p
                  className={`${
                    analyticsStats.downOrUp == "up"
                      ? "ab-status"
                      : "ab-status-green"
                  }`}
                >
                  {data}
                </p>
              </div>
            </div>
            {analyticsStats.table_details.map((e, index) => (
              <div className="analytics-details" key={index}>
                <div>
                  <p>{e.time}</p>
                  <h2>{e.area}</h2>
                  <p
                    className={`${
                      e.downOrUp == "up" ? "ab-status" : "ab-status-green"
                    }`}
                  >
                    {e.stats}{" "}
                    {e.downOrUp == "up" ? (
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "0.8em" }}
                      >
                        trending_up
                      </span>
                    ) : (
                      e.downOrUp == "down" && (
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "0.8em" }}
                        >
                          trending_down
                        </span>
                      )
                    )}
                  </p>
                </div>
                <div>
                  <h3>{e.active}</h3>
                  <p>
                    {e.downOrUpWord} by {e.downOrUpBy}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="analytics-details">
          <div onClick={() => setOpenStatsBox(false)} className="ab-close-btn">
            <span>close</span>
          </div>
        </div> */}
        </div>
      )}
    </>
  );
}

export default AnalyticsBox;
